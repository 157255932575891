@font-face {
    font-family: "Caveat-Regular";
    src: url("../assets/fonts/Caveat-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Caveat-Bold";
    src: url("../assets/fonts/Caveat-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "GoodHeadlineOT";
    src: url("../assets/fonts/GoodHeadlineOT.woff2") format("woff2");
}

@font-face {
    font-family: "GoodHeadlineOT-News";
    src: url("../assets/fonts/GoodHeadlineOT-News.woff2") format("woff2");
}

@font-face {
    font-family: "GoodHeadlineOT-CondNews";
    src: url("../assets/fonts/GoodHeadlineOT-CondNews.woff2") format("woff2");
}

@font-face {
    font-family: "GoodHeadlineOT-CondBold";
    src: url("../assets/fonts/GoodHeadlineOT-CondBold.woff2") format("woff2");
}

@font-face {
    font-family: "GoodHeadlineOT-Bold";
    src: url("../assets/fonts/GoodHeadlineOT-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "GoodHeadlineOT-CondBlack";
    src: url("../assets/fonts/GoodHeadlineOT-CondBlack.woff2") format("woff2");
}

