@import "./fonts.scss";

//colors-------------------------------------
$white: #ffffff;
$black: #000000;
$greatLakesBlue: #A1CDEB;
$canadaRed:#F50014;
$mapleRed:#832926;

//fonts-------------------------------------
$ghOtNews:"GoodHeadlineOT-News";
$ghO:"GoodHeadlineOT";
$ghOtCondNews:"GoodHeadlineOT-CondNews";
$ghOtCondB:"GoodHeadlineOT-CondBold";
$ghOtB:"GoodHeadlineOT-Bold";
$ghOtCb:"GoodHeadlineOT-CondBlack";
$caveat:"Caveat-Regular";
$caveatB:"Caveat-Bold";

//width--------------------------------------
$widthSmall: 84vw;

//breakpoints--------------------------------
$breakpoints: (
  'small': (min-width: 481px),
  'medium': (min-width: 768px),
  'large': (min-width: 1025px),
  'xlarge': (min-width: 1201px),
  '2xlarge': (min-width: 2000px),
) !default;

//carousel------------------------------------
$nsLength:8;
$bcLength:9;

//nova-scotia---------------------------------
$main-width: 100%;
$tile-count:3;
$tile-width: 263px;
$spacing-large: 50px;
$spacing-small: 30px;
//width for title and body copy 
$content-width-desktop: 640px;
$content-width-tablet: 84%;
$content-width-mobile: 84%;