@import "../../Footer.scss";

.links {
    display: flex;
    text-align: left;
    width: 100%;
    align-self: center;
    margin-bottom: 0;
    @media (min-width: 1024px) {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-top: 32px;
    }
    @media (max-width: 1023px) {
        flex-wrap: nowrap;
        flex-direction: column;
        justify-content: center;
    }
    &__section {
        margin-bottom: 16px;
        color: $main-color;
        align-self: flex-start;
        @include flexCol;
        @media (min-width: 1280px) {
            width: calc(100% / 7);
        }
        @media (min-width: 1024px) and (max-width: 1279px) {
            width: calc(100% / 5);
        }
        @media (max-width: 1023px) {
            width: 100%;
            align-self: center;
        }
        &-last {
            margin-bottom: 0;
        }
        &__title {
            align-self: flex-start;
            font-size: 15px;
            line-height: 12px;
            margin-bottom: 12px;
            @include flexCol;
            @media (min-width: 1024px) {
                font-weight: 700;
            }
            @media (max-width: 1023px) {
                align-self: center;
                font-weight: 400;
            }
            @include linksText;
            @include hoverUnderline;
            @include hoverColor;
        }
        &__list {
            margin-top: 4px;
            align-self: flex-start;
            list-style-type: none;
            padding-left: 0;
            @media (max-width: 1023px) {
                display: none;
                align-self: center;
            }
        }
        &__listItem-item {
            font-size: 14px;
            letter-spacing: 0px;
            line-height: 10px;
            font-weight: 400;
            @include cursorPointer;
            @include hoverUnderline;
            @include linksText;
            @include hoverColor;
        }
    }
}

