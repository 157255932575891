@import "./variables.scss";

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin flexCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin flexRow {
  display: flex;
  justify-content: center;
}

@mixin background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin layout {
  width:100%;
  height: auto;
  align-self: center;
  @include flexCol;
}

@mixin minHeight {
  height: 100vh;
  min-height: 200vw;

  @include respond-to("medium") {
    min-height: 80vw;
  }
  @include respond-to("large") {
    min-height: 65vw;
  }
  @include respond-to("xlarge") {
   max-height: 100vh;
  }
}

@mixin underLineOnHover {
  &:hover {
    cursor: pointer;
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: $black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

@mixin marginBottom {
  margin-bottom: $spacing-large;
  @media (max-width: 767px) {
    margin-bottom: $spacing-small;
  }
}

@mixin paddingTopNBottom {
  padding: $spacing-large 0;
  @media (max-width: 767px) {
    padding: $spacing-small 0;
  }
}
@mixin commonWidth {
  align-self: center;
  width: $content-width-desktop;
  height: auto;
  @media (max-width: 767px) and (min-width: 481px){
      width: $content-width-tablet;
  }
  @media (max-width: 480px) {
      width: $content-width-mobile;
  }
}