.header {
  width: 100vw;
  height: 32px;
  display: flex;
  align-items: center;
  z-index: 10;
  margin: 0;
  padding: 0;
  min-width: 320px;
  &--black {
    background-color: #000000;
    top: 0;
    &__logo {
      margin-left: 24px;
      width: 32px;
      height: 100%;
      &-img {
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 11;
      }
    }
    &__chevron {
      align-self: center;
      width: 14.8px;
      height: 8px;
    }
    &__textArea {
      z-index: 11;
      align-self: center;
      margin-left: 32px;
      display: flex;
      align-items: center;
      text-decoration: none;
      &-text {
        color: #666666;
        align-self: center;
        padding-left: 8px;
        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana,
          sans-serif;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.04em;
      }
    }
  
  }
  &--gold {
    justify-content: center;
    text-align: center;
    background-color: #af8d43;
    z-index: 7;
    &__text {
      color: #ffffff;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.01em;
      text-align: center;
    }
  }
}