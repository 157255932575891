@import "../../Footer.scss";

.social {
    display: flex;
    align-items: center;
    @media (min-width: 1024px) {
        justify-content: space-between;
    }
    @media (max-width: 1023px) {
        flex-direction: column;
    }

    &__links {
        display: flex;
        align-items: center;
        @media (max-width: 1023px) {
            flex-direction: column;
        }
        &__divider {
            margin: 0 24px;
            height: 24px;
            border-left: 1px solid rgb(77, 77, 77);
            @media (max-width: 1023px) {
                display: none;
            }
        }

        &__copy {
            font-size: 12px;
            font-weight: 700;
            letter-spacing: 1.5px;
        }

        &__icons {
            list-style-type: none;
            display: flex;
            justify-content: flex-start;
            padding-left: 0;
            &-icon {
                width: 24px;
                height: 24px;
                outline: 0;
                margin-right: 16px;
                @include cursorPointer;
                @include hoverColor;
                @include linksText;
            }

            &-icon--insta {
                margin-right: 0;
            }
        }
    }
}

.cnn-badge-icon {
    width: 40px;
    height: 40px;
    outline: 0;
    @include cursorPointer;
    @media (max-width: 1023px) {
        margin-bottom: 24px;
    }
}
