@import "../../Footer.scss";

.search {
    align-self: center;
    width: 100%;
    position: relative;
    @include flexRow;

    &__bar {
        box-sizing: border-box;
        align-self: center;
        padding: 0 8px;
        width: 100%;
        height: 35px;
        border: 1px solid rgb(77, 77, 77);
        border-radius: 3px;
        font-family: $font-cnn;
        font-size: 14px;
        color: $footer-color;
    }
    &__button {
        align-self: center;
        position: absolute;
        background-color: transparent;
        white-space: nowrap;
        border: 0;
        right: 16px;
        @include cursorPointer;
        @include flexRow;
        &__text {
            color: #4d4d4d;
            font-size: 14px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0;
            margin: 0;
            @media (max-width: 1024px) {
                display: none;
            }
        }
    }
    &__iconContainer {
        align-self: center;
        @include flexRow;
    }

    &__icon {
        align-self: center;
        outline: 0;
        width: 24px;
        height: 24px;
        @media (min-width: 1025px) {
            display: none;
        }
    }
}

.arrow-right {
    outline: 0;
    width: 10px;
    height: 10px;
    margin-left: 8px;
    @media (max-width: 1024px) {
        display: none;
    }
}
