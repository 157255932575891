$main-color: #ffffff;
$font-cnn: "CNN Sans", "Helvetica Neue", "Helvetica", sans-serif;
$footer-color: #e6e6e6;

@mixin flexCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin flexRow {
    display: flex;
    justify-content: center;
}

@mixin hoverColor {
    &:hover {
        color: $main-color;
        fill: $main-color;
    }
}

@mixin hoverUnderline {
    &:hover {
        text-decoration: underline;
    }
}

@mixin cursorPointer {
    cursor: pointer;
}

@mixin linksText {
    text-decoration: none;
    transition: all 0.4s ease 0s;
    color: $footer-color;
}

.footer {
    background-color: #0c0c0c;
    width: 100vw;
    height: fit-content;
    color: $footer-color;
    font-family: $font-cnn;
    min-width: 320px;
    padding: 64px 0;
    @media (max-width: 1024px) {
        padding: 24px 0;
    }
    @include flexCol;
    &__contents {
        align-self: center;
        height: fit-content;
        @media (min-width: 1280px) {
            width: 1248px;
        }
        @media (max-width: 1279px) and (min-width: 1025px) {
            width: 964px;
        }

        @media (max-width: 1024px) and (min-width: 769px) {
            width: 720px;
        }
        @media (max-width: 768px) {
            width: 90%;
        }
        @include flexCol;
    }
}

.divider {
    border: 0px;
    height: 1px;
    width: 100%;
    margin: 24px 0;
    background: rgb(77, 77, 77);
    &--m {
        @media (min-width: 1024px) {
            display: none;
        }
    }
    &--d {
        @media (max-width: 1023px) {
            display: none;
        }
    }
}
