@import "../../Footer.scss";

.tvAudio {
    align-self: center;
    &-desktop {
        display: flex;
        flex-direction: row-reverse;
        @media (max-width: 1023px) {
            display: none;
        }
    }
    &-mobile {
        text-align: center;
        @include flexCol;
        @media (min-width: 1024px) {
            display: none;
        }
    }

    &__links {
        font-size: 18px;
        @include cursorPointer;
        @include hoverColor;
        @include linksText;
        @media (min-width: 1024px) {
            font-size: 15px;
        }
        &-tv {
            padding-bottom: 6px;
            @media (min-width: 1024px) {
                padding-bottom: 0;
                margin-left: 24px;
            }
        }
        &-audio {
            @media (max-width: 1023px) {
                padding-top: 6px;
            }
        }
    }
}
