@import "../../styles/App.scss";

.nav {
    // opacity: 0;
    box-sizing: border-box;
    width: 100%;
    min-width: 320px;
    height: 50px;
    color: $white;
    position: absolute;
    top: 64px;
    padding-left: 10px;
    padding-right: 20px;
    z-index: 10;
    @include flexRow;
    justify-content: space-between;
    @include respond-to("medium") {
        padding: 0 20px;
        height: auto;
    }
    @include respond-to("large") {
        padding-right: min(8vw, 120px);
        height: auto;
    }

    &__logo {
        align-self: center;
        width: 58px;
        height: 32px;
        margin-top: 10px;
        @include respond-to("small") {
            width: 100px;
            height: 55px;
            // margin-top: 20px;
        }

        &-img {
            width: 100%;
            height: 100%;
        }
    }

    &__links {
        @include flexRow;
        justify-content: flex-end;
        @include respond-to("large") {
            margin-right: 20px;
        }
    }

    &__item {
        align-self: flex-start;
        @include flexCol;
        padding: 0 14px;
        white-space: nowrap;
        padding-top: 50px;
        @include respond-to("small") {
            padding: 0 20px;
            padding-top: 20px;
        }
        // @include respond-to("large") {
        //     padding-top: 50px;
        // }
        &__link {
            display: inline-block;
            text-decoration: none;
            font-family: $ghOtB;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            color: $white;
            &:hover {
                color: $canadaRed;
            }
            &-dark {
                color: $black;
            }
            &--cs {
                margin: 0;
                font-family: $ghOtCb;
                font-style: normal;
                font-weight: 900;
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 1.5px;
                color:#FADA46;
                &-dark {
                    color: $mapleRed;
                }
            }
        }
    }
    &__menu {
        background: none;
        border: none;
        align-self: center;

        &:hover {
            cursor: pointer;
        }
        @include respond-to("small") {
            margin-right: 20px;
        }
        &__icon {
            width: 22px;
            height: 12px;
            fill: $white;
            &-dark {
                fill: $black;
            }
            &-light {
                fill: $white;
            }
        }
    }
}
