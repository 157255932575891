@import "../../styles/App.scss";

.carousel {
    opacity: 0;
    transition: opacity 0.7s linear;
    width: 100%;
    background: #F1EFEC;
    padding: 40px 0;
    color: $black;
    @include flexCol;
    justify-content: flex-start;
    @include respond-to("medium") {
       padding: 60px 0;
    }
    &__indicator {
        align-self: center;
        @include flexRow;
        justify-content: space-between;
        width: 107px;
        @include respond-to("medium") {
            width: 155px;
         }
        &__circle {
            fill: $greatLakesBlue;
            width: 9px;
            height: 9px;
            @include respond-to("medium") {
                width: 15px;
                height: 15px;
             }
             &-active {
                fill:$canadaRed;
             }
        }
    }
    &__contents{
        position: relative;
        align-self: center;
        width: 100%;
        padding-top: 10px;
        @include flexCol;
        @include respond-to("medium") {
            flex-direction: row;
            justify-content: center;
            height: 45vw;
            // padding-top: 0;
         } 
         @include respond-to("large") {
            flex-direction: row;
            justify-content: center;
            height: 45vw;
            padding-top: 0;
         }  
         @include respond-to("xlarge") {
            flex-direction: row;
            justify-content: center;
            height: auto;
            padding-top: 40px;
         } 
         &__arrows {
            width: 95%;
            z-index: 3;
            align-self:center;
            position: absolute;
            top:calc($widthSmall /2 - 6px);
            @include flexRow;
            @include respond-to("medium") {
                top:auto;
             }  
            justify-content: space-between;
            &__arrow {
                width: 25px;
                height: 25px;
                @include respond-to("medium") {
                    width: 35px;
                    height: 35px;
                 }  
              
                &-left {
                    transform: rotate(180deg);
                }
            }
         }
         &__photoContainer {
            align-self: center;
            width: $widthSmall;
            height: $widthSmall;
           
            overflow: hidden;
            @include flexRow;
            justify-content: flex-start;
            @include respond-to("medium") {
                width: 47vw;
                max-width: 670px;
                max-height: 523px;
                height: 36.7vw;
                margin-right: 22px;
             }  
             &__photos {
                align-self: center;
                width: calc($widthSmall * $nsLength);
                height: $widthSmall;
                @include flexRow;
                justify-content: flex-start;
                @include respond-to("medium") {
                    width: calc(47vw * $nsLength);
                    max-width: calc(670px * $nsLength);
                    height: min(36.7vw, 523px);
                 }
                 &--bc {
                    width: calc($widthSmall * $bcLength);
                    @include respond-to("medium") {
                        width: calc(47vw * $bcLength);
                        max-width: calc(670px * $bcLength);

                     }
                 }
                 &__imgs {
                    align-self: center;
                    width: $widthSmall;
                    height: $widthSmall;
                    overflow: hidden;
                    @include flexRow;
                    @include respond-to("medium") {
                        width: min(47vw, 670px);
                        height: min(36.7vw, 523px);
                     } 

                 }
                 &__img{
                    overflow: hidden;
                    align-self: center;
                    // transform: scale(1.7);
                    height: 100%;
                    width: 100%;
                 }
             }
        }
        &__text {
            align-self: center;
            width: $widthSmall;
            height: 350px;
            @include respond-to("small") {
                height: 300px;
             }  
            @include respond-to("medium") {
                width: min(32vw, 450px);
                height: auto;
             }  
        }
    }
    &__button {
        width:fit-content;
        @include flexRow;
        &--margin{
            margin-top: 20px;
        }
    }
   
}

.carouselName--bold {
    font-family: $ghOtB;
    font-weight: 700;
}

.soundIcon {
    width: 17px;
    height: 17px;
    margin-left: 5px;
    @include respond-to("medium") {
        width: 25px;
        height: 25px;
     }  
}
