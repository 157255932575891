@import "../../Footer.scss";

.logIn {
    align-self: center;
    text-decoration: none;
    color: $footer-color;;
    border: none;
    @include flexCol;
    &__icon {
        align-self: center;
        width: 24px;
        height: 24px;
        fill: $footer-color;
        margin-bottom: 7px;
        @include cursorPointer;
        @include hoverColor;
        @include linksText;
        @media (min-width: 1024px) {
            display: none;
        }
    }
    
    &__text {
        align-self: center;
        color: $footer-color;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.2px;
        font-weight: bold;
        border: none;
        background-color: rgb(12, 12, 12);
        margin-top: 5px;
        @include cursorPointer;
        @include hoverColor;
        @include linksText;
        @media (min-width: 1024px) {
            margin-top: 0;
            font-size: 14px;
            line-height: 19px;
        }
    }
    &-desktop {
        border-radius: 8px;
        height: 31px;
        width: 60px;
        border: 1px solid rgb(255, 255, 255);
        @media (max-width: 1023px) {
            display: none;
        }
    }
    &-mobile {
        padding-top: 32px;
        @media (min-width: 1024px) {
            display: none;
        }
    }
}
