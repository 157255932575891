

@function moveD($num) {
    @return calc(min(47vw, 670px) * $num);
}
@function moveM($num) {
    @return 84vw * $num;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(-3px);
    }
    50% {
        transform: translateY(0);
    }
 
    75% {
        transform: translateY(3px);
    }
    100% {
        transform: translateY(0);
    }
}


@keyframes fade-in {
    0% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.1;
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.7);
    }
}
@keyframes scaleDown {
    0% {
        transform: scale(1.7);
    }

    100% {
        transform: scale(1);
    }
}


@keyframes moveTo1 {
    0% {
        transform: translateX(moveM(-1));
       
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes moveTo2 {
    0% {
        transform: translateX(moveM(-2));
       
    }
    100% {
        transform: translateX(moveM(-1));
       
    }
}
@keyframes moveTo3 {
    0% {
        transform: translateX(moveM(-3));
       
    }
    100% {
        transform: translateX(moveM(-2));
       
    }
}
@keyframes moveTo4 {
    0% {
        transform: translateX(moveM(-4));
        
    }
    100% {
        transform: translateX(moveM(-3));
       
    }
}
@keyframes moveTo5 {
    0% {
        transform: translateX(moveM(-5));
        
    }
    100% {
        transform: translateX(moveM(-4));
        
    }
}
@keyframes moveTo6 {
    0% {
        transform: translateX(moveM(-6));
        
    }
    100% {
        transform: translateX(moveM(-5));
       
    }
}

@keyframes moveTo7 {
    0% {
        transform: translateX(moveM(-7));
        
    }
    100% {
        transform: translateX(moveM(-6));
       
    }
}
@keyframes moveTo8 {
    0% {
        transform: translateX(0);
       
    }
    100% {
        transform: translateX(moveM(-7));
     
    }
}
@keyframes moveTo8BC {
    0% {
        transform: translateX(moveM(-8));
       
    }
    100% {
        transform: translateX(moveM(-7));
     
    }
}
@keyframes moveTo9 {
    0% {
        transform: translateX(0);
       
    }
    100% {
        transform: translateX(moveM(-8));
     
    }
}

@keyframes moveTo1R {
    0% {
        transform: translateX(moveM(-7));
     
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes moveTo2R {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(moveM(-1));
       
    }
}

@keyframes moveTo3R {
    0% {
        transform: translateX(moveM(-1));
       
    }
    100% {
        transform: translateX(moveM(-2));
       
    }
}

@keyframes moveTo4R {
    0% {
        transform: translateX(moveM(-2));
    }
    100% {
        transform: translateX(moveM(-3));
    }
}

@keyframes moveTo5R {
    0% {
        transform: translateX(moveM(-3));
      
    }
    100% {
        transform: translateX(moveM(-4));
       
    }
}
@keyframes moveTo6R {
    0% {
        transform: translateX(moveM(-4));
       
    }
    100% {
        transform: translateX(moveM(-5));
       
        
    }
}
@keyframes moveTo7R {
    0% {
        transform: translateX(moveM(-5));
     
    }
    100% {
        transform: translateX(moveM(-6));
    }
}
@keyframes moveTo8R {
    0% {
        transform: translateX(moveM(-6));
    }
    100% {
        transform: translateX(moveM(-7));
    }
}
@keyframes moveTo9R {
    0% {
        transform: translateX(moveM(-7));
    }
    100% {
        transform: translateX(moveM(-8));
    }
}




@media screen and (min-width: 768px)  {
    @keyframes moveTo1 {
        0% {
            transform: translateX(moveD(-1));
           
        }
        100% {
            transform: translateX(0);
        }
    }
    @keyframes moveTo2 {
        0% {
            transform: translateX(moveD(-2));
           
        }
        100% {
            transform: translateX(moveD(-1));
           
        }
    }
    @keyframes moveTo3 {
        0% {
            transform: translateX(moveD(-3));
           
        }
        100% {
            transform: translateX(moveD(-2));
           
        }
    }
    @keyframes moveTo4 {
        0% {
            transform: translateX(moveD(-4));
            
        }
        100% {
            transform: translateX(moveD(-3));
           
        }
    }
    @keyframes moveTo5 {
        0% {
            transform: translateX(moveD(-5));
            
        }
        100% {
            transform: translateX(moveD(-4));
            
        }
    }
    @keyframes moveTo6 {
        0% {
            transform: translateX(moveD(-6));
            
        }
        100% {
            transform: translateX(moveD(-5));
           
        }
    }
    
    @keyframes moveTo7 {
        0% {
            transform: translateX(moveD(-7));
            
        }
        100% {
            transform: translateX(moveD(-6));
           
        }
    }
    @keyframes moveTo8 {
        0% {
            transform: translateX(moveD(0));
           
        }
        100% {
            transform: translateX(moveD(-7));
         
        }
    }
    @keyframes moveTo8BC {
        0% {
            transform: translateX(moveD(-8));
           
        }
        100% {
            transform: translateX(moveD(-7));
         
        }
    }
    @keyframes moveTo9 {
        0% {
            transform: translateX(moveD(0));
           
        }
        100% {
            transform: translateX(moveD(-8));
         
        }
    }
   
    
    @keyframes moveTo1R {
        0% {
            transform: translateX(moveD(-7));
         
        }
        100% {
            transform: translateX(0);
        }
    }
    
    @keyframes moveTo2R {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(moveD(-1));
           
        }
    }
    
    @keyframes moveTo3R {
        0% {
            transform: translateX(moveD(-1));
           
        }
        100% {
            transform: translateX(moveD(-2));
           
        }
    }
    
    @keyframes moveTo4R {
        0% {
            transform: translateX(moveD(-2));
        }
        100% {
            transform: translateX(moveD(-3));
        }
    }
    
    @keyframes moveTo5R {
        0% {
            transform: translateX(moveD(-3));
          
        }
        100% {
            transform: translateX(moveD(-4));
           
        }
    }
    @keyframes moveTo6R {
        0% {
            transform: translateX(moveD(-4));
           
        }
        100% {
            transform: translateX(moveD(-5));
           
            
        }
    }
    @keyframes moveTo7R {
        0% {
            transform: translateX(moveD(-5));
         
        }
        100% {
            transform: translateX(moveD(-6));
        }
    }
    @keyframes moveTo8R {
        0% {
            transform: translateX(moveD(-6));
        }
        100% {
            transform: translateX(moveD(-7));
        }
    }
    @keyframes moveTo9R {
        0% {
            transform: translateX(moveD(-7));
        }
        100% {
            transform: translateX(moveD(-8));
        }
    }
}

