@import "./variables.scss";
@import "./mixins.scss";
@import "./fonts.scss";
@import "./animations.scss";

.App {
  width: 100%;
  min-width: 320px;
  color: $white;
  overflow: hidden;
  position: relative;
  @include flexCol;
}

.skip {
  align-self: center;
  @include flexRow;
}

.skip a {
  position: absolute;
  align-self: center;
  top: -60px;
  // top:0;
  color: $white;
  background-color: $mapleRed;
  color: $white;
  font-family: $ghOtCondB;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding: 8.5px 10px;
  align-self: center;
  &:focus {
    top: 0;
  }
}

//fonts-------------------------

.copy {
  font-family: $ghOtNews;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 22px;
  @include respond-to("large") {
    font-size: 18px;
    line-height: 28px;
  }
  @include respond-to("xlarge") {
    font-size: 20px;
    line-height: 32px;
  }
}
.localTip {
  @include respond-to("large") {
    font-size: 16px;
    line-height: 26px;
  }
  @include respond-to("xlarge") {
    font-size: 17px;
    line-height: 27px;
  }
}
p.play {
  font-family: $ghOtCondB;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.997462px;
  @include respond-to("medium") {
    font-size: 16px;
    line-height: 15px;
    letter-spacing: 1.5px;
  }
}

p.carouselName {
  font-family: $ghOtNews;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  @include respond-to("large") {
    font-size: 18px;
    line-height: 26px;
  }
}

h4.carouselTitle {
  font-family: $ghOtCondB;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  @include respond-to("medium") {
    font-size: 18px;
    margin-top: 0;
  }
  @include respond-to("large") {
    font-size: 20px;
  }
  &--ontario {
    @include respond-to("medium") {
      margin-bottom: 14px;
    }
    @include respond-to("large") {
      margin-bottom: 16px;
    }
  }
}

.videoHead {
  font-family: $ghOtNews;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  @include respond-to("medium") {
    font-size: 16px;
    line-height: 30px;
    margin-top: 0;
  }
}

h3.videoSubhead {
  font-family: $ghOtCondB;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 16px;
  @include respond-to("medium") {
    font-size: 24px;
    line-height: 30px;
    margin-top: 30px;
  }
}

h3.bodyHeadline {
  font-family: $ghOtCondB;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.carouselHead {
  font-family: $ghOtCondNews;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  @include respond-to("medium") {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
  }
}

.videoTitle {
  font-family: $caveat;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 1px;
  margin: 0;
  @include respond-to("medium") {
    font-size: 64px;
    line-height: 32px;
  }
}

.carouselSubhead {
  font-family: $ghOtCondB;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 0;
  @include respond-to("medium") {
    font-size: 46px;
    line-height: 28px;
  }
  @include respond-to("large") {
    font-size: 64px;
    line-height: 32px;
  }
}

.carouselSource {
  font-family: $ghO;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  @include respond-to("medium") {
    font-size: 14px;
  }
  &--italic {
    font-style: italic;
  }
  &--margin {
   padding-bottom: 20px;
  }
}

blockquote {
  margin: 10px 0;
  // @include respond-to("medium") {
  //   margin: 12px 0;
  // }
  @include respond-to("large") {
    margin: 16px 0;
  }
}

button {
  border: none;
  background-color: transparent;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    cursor: pointer;
  }
}

.carouselButton {
  background-color: $mapleRed;
  transition: background-color 0.3s ease-in;
  align-self: center;
  color: $white;
  font-family: $ghOtCondB;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  padding: 0 15px;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  width: fit-content;
  @include respond-to("medium") {
    font-size: 16px;
    height: 45px;
  }
  &:hover {
    background-color: $canadaRed;
  }
  &:focus {
    background-color: $canadaRed;
  }
  &-link {
    align-self: center;
    &--article {
      margin-top: 24px;
      @include respond-to("medium") {
        margin-top: 30px;
      }
    }
  }
}

a {
  text-decoration: none;
  color: $white;
}

.it {
  font-style: italic;
}

.words {
  transform: scale(0.1);
  opacity: 0;
}
