@import "../../styles/App.scss";
@import "../../styles/variables.scss";

.hero {
    position: relative;
    overflow: hidden;
    background-image: url("../../assets/hero/hero_bg_mobile.png");
    @include background;
    @include flexCol;
    height: 90vh;
    min-height: 180vw;
    @include respond-to("small") {
        min-height: 150vw;
    }
    @include respond-to("medium") {
        flex-direction: row;
        justify-content: center;
        background-image: url("../../assets/hero/hero_bg.webp");
        min-height: 65vw;
    }
    @include respond-to("xlarge") {
        min-height: 50vw;
    }
    &__map {
        position: absolute;
        top: -5%;
        width: 100%;
        height: auto;

        @include respond-to("medium") {
            align-self: center;
            width: 73%;
            left: 0;
            top: auto;
            z-index: 1;
        }
    }
    &__svg {
        position: absolute;
        top: -5%;
        width: 100%;
        height: auto;
        z-index: 2;
        @include respond-to("medium") {
            align-self: center;
            width: 73%;
            left: 0;
            top: auto;
            z-index: 1;
        }
        &__province .svg__fill {
            fill: #ed2024;
            opacity: 0.1;
        }
        &__province:hover .svg__fill {
            fill: $mapleRed;
            opacity: 0.6;
            cursor: pointer;
        }
        &__province:focus .svg__fill {
            fill: $mapleRed;
            opacity: 0.6;
          
        }
    }
    &__content {
        position: absolute;
        align-self: center;
        margin-top: 50vw;
        width: $widthSmall;
        @include respond-to("small") {
            margin-top: 70vw;
        }
        @include respond-to("medium") {
            right: 6vw;
            z-index: 2;
            width: 35%;
            max-width: 489px;
            margin-top: 0;
        }
        @include respond-to("medium") {
            right: 6vw;
            z-index: 2;
            width: 35%;
            max-width: 489px;
            margin-top: 0;
        }
        @include respond-to("large") {
            right: 8.3vw;
        }

        &-title {
            font-family: $caveatB;
            white-space: nowrap;
            font-style: normal;
            font-weight: 700;
            text-align: center;
            letter-spacing: 1.3px;
            font-size: 50px;
            line-height: 40px;
            margin-bottom: 15px;
            @include respond-to("medium") {
                font-size: 36px;
                margin-bottom: 10px;
            }
            @include respond-to("large") {
                font-size: 50px;
                margin-bottom: 20px;
            }
            @include respond-to("xlarge") {
                font-size: 78px;
                margin-bottom: 40px;
            }
        }
    }

    &__scroll {
        align-self: center;
        @include flexCol;
        &-mobile {
            display: flex;
            @include respond-to("medium") {
                display: none;
            }
        }
        &-desktop {
            display: none;
            @include respond-to("medium") {
                display: flex;
                position: absolute;
                bottom: 60px;
                z-index: 1;
            }
        }
        &__cap {
            align-self: center;
            font-family: $ghOtCondB;
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 1px;
            margin-bottom: 6px;
            @include respond-to("small") {
                font-size: 12px;
                letter-spacing: 1.5px;
            }
        }
        &__mouse {
            align-self: center;
            width: 30px;
            height: 45px;
            &__arrow {
                animation: 2s linear 2s infinite scroll;
            }
        }
    }
}

.words {
    transform: scale(0.1);
    opacity: 0;
}


.svg_0--mobile {
    fill: none;
}

