@import "../../styles/App.scss";

.cta {
    opacity: 0;
    transition: opacity 0.7s linear;
    align-self: center;
    width: 100%;
    background-color: $greatLakesBlue;
    height: 300px;
    color: $black;
    text-align: center;
    @include respond-to("large") {
        height: 416px;
    }
    @include flexCol;
    &__logo {
        align-self: center;
        width: 69px;
        @include respond-to("medium") {
            width: 98px;
        }
    }
    & p {
        align-self: center;
        width: $widthSmall;
        max-width: 610px;
        margin: 24px 0;
        @include respond-to("medium") {
            width: 43vw;
            margin: 40px 0;
        }

    }
}