@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";
@import "../../styles/fonts";

.mobileMenu {
    width: 100%;
    min-width: 320px;
    position: absolute;
    top: 64px;
    z-index: 11;
    background-color: $white;
    transform: translateX(max(100%, 320px));
    @include respond-to("medium") {
        display: none;
    }
    @include layout;
    &__contents {
        width: 100%;
        // padding: 0 30px;
        padding-top: 10%;
        height: calc(100vh - 64px);
        box-sizing: border-box;
        position: relative;
        align-self: center;
        @include flexCol;
        justify-content: space-evenly;
        & ul {
            align-self: center;
            width: $widthSmall;
            padding: 0;
            @include flexCol;
        }
        &__close {
            margin: 5vw;
            z-index: 3;
            border: none;
            background-color: transparent;
            position: absolute;
            box-sizing: border-box;
            top: 0;
            right: 0;
            &:hover {
                cursor: pointer;
            }
            @include flexRow;
            &-img {
                align-self: center;
                width: 30px;
                height: 30px;
            }
        }
        &__item {
            @include flexCol;
            justify-content: flex-start;
            &__link {
                align-self: flex-start;
                text-decoration: none;
                color: $black;
                font-family: $ghOtNews;
                font-style: normal;
                font-weight: 450;
                font-size: 16px;
                line-height: 24px;
                // position: relative;
                @include flexRow;
                // @include underLineOnHover;
                // &:hover {
                //     color: $mapleRed;
                // }
            }
            & p {
                color: $canadaRed;
                font-family: $ghOtCondB;
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 24px;
                letter-spacing: 1.5px;
                margin: 0;
            }
            &__bar {
                margin: 3% 0;
                width: 100%;
                height: 1px;
                min-height: 1px;
                background-color: $black;
            }
        }

        &__logo {
            align-self: flex-start;
            width: 118px;
            height: 65px;
            margin-left: 5vw;
            &-img {
                width: 100%;
                height: 100%;
            }
        }
    }
    &__arrow {
        margin-left: 10px;
        width: 5.5px;
        height: 10px;
    }
}
