@import "../../styles/App.scss";

.episode {
    align-self: center;
    position: relative;
    width: 100%;
    @include background;
    @include minHeight;

    &-contents {
        opacity: 0;
        transition: opacity 0.7s linear;
        position: absolute;
        box-sizing: border-box;
        align-self: center;
        z-index: 1;
        width: 100%;
        @include minHeight;
        @include flexCol;
        @include respond-to("medium") {
            margin-top: 0;
        }
        &--3 {
            align-self: center;
            text-align: center;
            height: 100vw;
            min-height: 100vw;
            padding: 0 8vw;
            @include respond-to("medium") {
                height: 100vh;
                min-height: 56.25vw;
                width: 100%;
                padding: 0;
            }

            & p {
                align-self: center;
                max-width: 590px;
                margin: 24px 0;
                @include respond-to("medium") {
                    margin: 30px 0;
                    width: 40vw;
                }
            }
        }
    }
    &__videoloop {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        align-self: center;
        object-fit: cover;
        &--0 {
            @include respond-to("small") {
                object-position: 75% center;
            }
            @include respond-to("large") {
                object-position: 60% center;
            }
            @include respond-to("xlarge") {
                object-position: center;
            }
        }
    }
    &__overlay {
        position: absolute;
        z-index: 0;
        width: 100%;
        @include minHeight;
        background: rgba(0, 0, 0, 0.25);
    }

    &--3 {
        height: 100vw;
        min-height: 100vw;

        background-image: url("../../assets/episode/woc_bg_mobile.png");
        @include respond-to("medium") {
            height: 100vh;
            min-height: 56.25vw;
            background-image: url("../../assets/episode/woc_bg_desktop.png");
        }
    }
    &__video {
        align-self: center;
        @include flexCol;
        @include respond-to("medium") {
            margin-top: 64px;
        }
    }
    &__button {
        align-self: center;
        margin-top: 24px;
        width: 60px;
        height: 60px;
        @include respond-to("medium") {
            margin-top: 40px;
        }
        &:hover {
            cursor: pointer;
        }
        &:focus {
            cursor: pointer;
        }
    }
    &__body {
        align-self: center;
        width: $widthSmall;
        @include flexCol;
        @include respond-to("medium") {
            width: 100%;
            flex-direction: row-reverse;
            justify-content: center;
        }
        &__map {
            width: 100%;
            margin-top: -60px;
            max-width: 621.5px;
            @include respond-to("medium") {
                width: 43%;
                margin-top: 0;
            }
        }
        &__traveler {
            align-self: center;
            width: 100%;
            max-width: 488px;
            margin-top: -60px;
            @include flexCol;
            @include respond-to("medium") {
                width: 35%;
                margin-top: 0;
                margin-right: 2.8vw;
            }
            &--bc {
                margin-top: -30px;
                @include respond-to("medium") {
                    margin-top: 0;
                }
            }
            & p {
                text-align: center;
            }
            &__img {
                align-self: center;
                width: 36vw;
                @include respond-to("medium") {
                    width: 46%;
                }
            }
        }
    }
}
