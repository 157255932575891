@import "../../Footer.scss";

.corporate {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media (min-width: 1024px) {
        align-self: start;
        justify-content: flex-start;
    }
    @media (max-width: 1023px) {
        justify-content: center;
        align-self: center;
        max-width: 720px;
    }
    &__nav {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;
        @media (min-width: 1024px) {
            justify-content: flex-start;
            align-self: start;
        }
        @media (max-width: 1023px) {
            justify-content: center;
            align-self: center;
        }
        &-link {
            white-space: nowrap;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0px;
            margin: 0px 16px 4px 0px;
            @include linksText;
            @include hoverColor;
            @include hoverUnderline;
            @media (min-width: 1024px) {
                line-height: 40px;
            }
        }
    }
    &__copyright {
        margin: 0;
        font-size: 12px;
        line-height: 19px;
        @media (min-width: 1024px) {
            align-self: start;
        }
        @media (max-width: 1023px) {
            align-self: center;
            text-align: center;
        }
        &-text {
            padding-right: 8px;
        }
    }
}
